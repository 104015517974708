import '@/scss/main.scss'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueClipboard from 'vue-clipboard2'
import WebpPlugun from 'vue-webp-plugin'
import Buefy from 'buefy'
import numeral from 'numeral'
import api from '@/api/explorer'
import VueTranslate from 'vue-translate-plugin'
import Locales from './locales'

// Common components
import SvgIcon from '@/components/common/SvgIcon'
import Widgets from '@/components/common/Widgets'
Vue.component('SvgIcon', SvgIcon)
Vue.component('Widgets', Widgets)

Vue.use(VueTranslate)
Vue.locales(Locales)

Vue.use(require('vue-moment'))
Vue.use(Buefy)
Vue.use(VueClipboard)
Vue.use(WebpPlugun)

Vue.config.productionTip = false

Vue.prototype.$langs = {
  ru: { title: 'Русский', flag: 'ru' },
  en: { title: 'English', flag: 'us' },
  es: { title: 'Español', flag: 'es' },
  zh: { title: '中文', flag: 'cn' },
  id: { title: 'Indonesia', flag: 'id' }
}

const isDev = () => location.host.indexOf('.dev.') > -1
Vue.prototype.$website = `https://${isDev() ? 'website.dev.' : ''}umi.top/`
Vue.prototype.$wallet = `https://wallet${isDev() ? '.dev' : ''}.umi.top/`
// Vue.prototype.$blockchain = `https://blockchain${isDev() ? '.dev' : ''}.umi.top/`
Vue.prototype.$blockchain = (() => {
  const network = localStorage.getItem('new_network')
  if (window.location.host.startsWith('blockchain-testnet-v1.')) 
      return 'https://blockchain-testnet-v1.umi.top/'
  else if (window.location.host.startsWith('blockchain.'))
      return 'https://blockchain.umi.top/'
  else if (window.location.host.startsWith('blockchain-testnet.'))
      return 'https://blockchain-testnet.umi.top/'
  else if (window.location.host.startsWith('blockchain-v1.'))
      return 'https://blockchain-v1.umi.top/'
})()
Vue.prototype.$blog = `https://blog${isDev() ? '.dev' : ''}.umi.top/`
Vue.prototype.$stats = `https://stats${isDev() ? '.dev' : ''}.umi.top/`
Vue.prototype.$oneapp = 'https://app.umi.top/'
Vue.prototype.$docs = 'https://documents-s3.dev.umi.top/'

Vue.mixin({
  methods: {
    toUmi (value, round = 2, denominator = 100) {
      return numeral(value / denominator).format(round === 0 ? '0,0' : `0,0.${'0'.repeat(round)}`)
    },
    pretty (value, withSuffix = true, rounded = false) {
      const suffix = withSuffix ? ' ' + this.$store.getters.currency : ''
      const amount = (value / 100) * this.$store.getters.course
      if (rounded && this.$store.getters.decimal <= 2) {
        return numeral(amount).format('0,0') + suffix
      }
      if (amount < 0.1 && this.$store.getters.decimal === 2) {
        return (
          numeral(amount).format(
            '0,0.' + '0'.repeat(this.$store.getters.decimal + 1)
          ) + suffix
        )
      }
      if (amount > 0.01) {
        return (
          numeral(amount).format(
            '0,0.' + '0'.repeat(this.$store.getters.decimal)
          ) + suffix
        )
      }
      return amount.toFixed(this.$store.getters.decimal) + suffix
    },
    convert (value, currency, rounded = true) {
      const suffix = currency ? ' ' + currency : ''
      const decimal = currency === 'BTC' ? 8 : 2
      if (rounded && decimal <= 2) {
        return (
          numeral(value).format('0,0') + suffix
        )
      }
      return (
        numeral(value).format(
          '0,0.' + '0'.repeat(decimal)
        ) + suffix
      )
    },
    onCopy () {
      this.$buefy.snackbar.open({
        queue: true,
        message: this.$translate.text('Copied to clipboard!'),
        actionText: '×'
      })
    },
    cut (hash, count = 10) {
      if (hash.length === 1) return hash
      try {
        return hash.substr(0, count) + '...' + hash.substr(hash.length - count)
      } catch (e) {
        console.log(e)
      }
    },
    find (keyword) {
      const key = keyword.trim()
      if (key && (key.length === 62 || key.length === 64 || /^\d+$/.test(key))) {
        api
          .search(key)
          .then(r => {
            router.push({
              path: r.data.data.replace(process.env.VUE_APP_BLOCKCHAIN_URL, '').replace(process.env.VUE_APP_BLOCKCHAIN_TESTNET_URL, '')
            })
          })
          .catch(() => {
            this.$buefy.snackbar.open({
              message: this.$translate.text('Nothing found!'),
              actionText: '×',
              queue: true
            })
          })
      }
    }
  }
})

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
